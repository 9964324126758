import { postRequest } from '../../../utils/apiUtils';
import { allValuationsFailure, allValuationsSuccess, initiateCall } from '../../reducers/slices/Valuation/allValuationsSlice';

export const GetAllValuations = (data) => {
  return async (dispatch) => {
    dispatch(initiateCall());
    const { result, error } = await postRequest(`admin/property`, data);

    if (!error) {
      return dispatch(allValuationsSuccess(result));
    }

    return dispatch(allValuationsFailure(result));
  };
};
