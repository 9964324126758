import { createSlice } from '@reduxjs/toolkit';

const initialState = { allValuationsData: [], error: false, loadingAllValuations: true };

export const getAllValuationSlices = createSlice({
  name: 'allValidation',
  initialState,
  reducers: {
    initiateCall: (state) => {
      state.loadingAllValuations = true;
    },
    allValuationsSuccess: (state, action) => {
      state.allValuationsData = action.payload;
      state.loadingAllValuations = false;
    },
    allValuationsFailure: (state, action) => {
      state.allValuationsData = action.payload;
      state.loadingAllValuations = false;
      state.error = true;
    },
  },
});

export const { allValuationsSuccess, allValuationsFailure, initiateCall } = getAllValuationSlices.actions;

export default getAllValuationSlices.reducer;
