import React, { useEffect, useState } from 'react';
import { Button, Input, Popconfirm, Spin, Table, Tag, notification } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateListAsync } from '../../container/reducers/slices/Notification/NotificationTemplateSlice';
import './Notification.css';
import { UserStatus } from '../../components/common/userTypes';
import { getNotificationListAsync, selectNotificationLoading, selectNotificationList, deleteNotificationAsync } from '../../container/reducers/slices/Notification/NotificationSlice';
import dayjs from 'dayjs';
import NewNotification from './NewNotification';
import PreviewNotification from './PreviewNotification';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const Notification = () => {
  const [open, setOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [id, setId] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const loading = useSelector(selectNotificationLoading);
  const notificationList = useSelector(selectNotificationList);
  const [filteredList, setFilteredList] = useState(notificationList);

  const dispatch = useDispatch();

  useEffect(() => {
    const filteredData = notificationList.filter((template) => template.name.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredList(filteredData);
  }, [searchValue, notificationList]);

  useEffect(() => {
    dispatch(getNotificationListAsync());
  }, [dispatch]);

  function handleTemplateEdit(id) {
    setId(id);
    setPreviewOpen(true);
  }

  const handlePopConfirm = async (id) => {
    try {
      const res = await dispatch(deleteNotificationAsync(id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(getNotificationListAsync());
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message,
      });
      return;
    }
  };

  const columns = [
    {
      title: 'Notification Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => {
        return <>{text}</>;
      },
    },
    {
      title: 'Schedule Date and Time',
      dataIndex: 'schedule_at',
      key: 'schedule_at',
      render: (text) => dayjs(text).format('LLL'),
    },
    {
      title: 'Status',
      dataIndex: 'delivery_status',
      key: 'delivery_status',
      sorter: (a, b) => a.delivery_status - b.delivery_status,
      render: (text) => <Tag color={`${text === 3 ? 'red' : 'green'}`}> {Object.keys(UserStatus).find((key) => UserStatus[key] === text)} </Tag>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <p className="d-flex gap-3 mx-2">
            <Button icon={<EyeOutlined />} onClick={() => handleTemplateEdit(record.id)} type="primary" size="large" />
            <Popconfirm title="Delete the Notification" onConfirm={() => handlePopConfirm(record.id)} okText="Yes" cancelText="No">
              <Button icon={<DeleteOutlined />} type="primary" size="large" />
            </Popconfirm>
          </p>
        );
      },
    },
  ];

  const handleModalOpen = () => {
    dispatch(getTemplateListAsync());
    setOpen(true);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <>
      <div className="d-flex flex-column gap-5 align-items-center bg-white p-4 email-template-parent">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex gap-3 justify-content-between align-items-center">
            <div>
              <h5 className="m-0 p-0">Notifications</h5>
            </div>
            <div>
              <Input.Search onChange={(e) => handleSearch(e.target.value)} allowClear placeholder="Search notification name" className="email-input" />
            </div>
          </div>
          <div>
            <Button type="primary" size="large" onClick={handleModalOpen}>
              New Notification
            </Button>
          </div>
        </div>

        <div className="row w-100">
          <div className="col-12">
            <div className="dashboard_list">
              <Spin spinning={loading} size="large">
                <Table
                  rowKey={'id'}
                  columns={columns}
                  dataSource={filteredList}
                  pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />
              </Spin>
            </div>
          </div>
        </div>
      </div>

      <NewNotification open={open} setOpen={setOpen} />

      <PreviewNotification open={previewOpen} setOpen={setPreviewOpen} id={id} setId={setId} />
    </>
  );
};

export default Notification;
