import React, { useEffect, useState } from 'react';
import Overview from './Overview';
import { UserOutlined, BankOutlined, LeftOutlined } from '@ant-design/icons';
import { Tabs, Button } from 'antd';
import BankDetails from './BankDetails';
import { useNavigate } from 'react-router-dom';
import './BasiqMultipleAccounts.css';
import { CustomIcon } from '../../assets/icons';
import onboarding from '../../assets/images/onboarding.svg';
import subUser from '../../assets/images/subUser.svg';
import TeamMembers from '../TaxAccountant/TeamMembers';
import TaxAccountantRegisteredClients from '../TaxAccountant/RegisteredClients';
import ManagedClients from '../TaxAccountant/ManagedClients';

const UserOverview = () => {
  const navigate = useNavigate();
  const [isTaxAccountant, setIsTaxAccountant] = useState(false);
  const [isManagedClients, setIsManagedClients] = useState(false);

  const pathname = window.location.pathname;

  useEffect(() => {
    const isTaxAccountant = pathname?.split('/')?.[2] === 'tax-accountant';
    setIsTaxAccountant(isTaxAccountant);
  }, [pathname]);

  useEffect(() => {
    const isManagedClient = pathname?.split('/')?.[2] === 'managed-users';
    setIsManagedClients(isManagedClient);
  }, [pathname]);

  const items = [
    {
      label: `Overview`,
      key: '1',
      children: <Overview />,
      icon: <UserOutlined />,
    },
    {
      label: `Bank Details`,
      key: '2',
      children: <BankDetails />,
      icon: <BankOutlined />,
    },
  ];

  const taxAccountantItems = [
    {
      label: `Overview`,
      key: '1',
      children: <Overview />,
      icon: <UserOutlined />,
    },
    {
      label: `Team Members`,
      key: '3',
      children: <TeamMembers />,
      icon: <CustomIcon icon={onboarding} />,
    },
    {
      label: `Registered Clients`,
      key: '4',
      children: <TaxAccountantRegisteredClients />,
      icon: <CustomIcon icon={subUser} />,
    },
    {
      label: `Managed Clients`,
      key: '5',
      children: <ManagedClients />,
      icon: <UserOutlined />,
    },
  ];

  const handleClick = () => {
    navigate(`/user/${isTaxAccountant ? 'tax-accountant' : isManagedClients ? 'managed-users' : 'property-investor'}`);
  };

  return (
    <div className="customTab">
      <div className="d-flex justify-content-start align-items-center gap-2">
        <Button onClick={() => handleClick()} icon={<LeftOutlined />} />
        <h5 className="p-0 m-0">User Details </h5>
      </div>
      <Tabs destroyInactiveTabPane type="card" defaultActiveKey="1" items={isTaxAccountant ? taxAccountantItems : items} className="mt-3 user-tabs" />
    </div>
  );
};

export default UserOverview;
