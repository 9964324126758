import { Button, DatePicker, Form, Input, notification, Table, Tag, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PencilIcon } from '../../assets/icons';
import { GetAllValuations } from '../../container/actions/valuations/getAllValuations.action';
import { formatNumberToCurrency, isValidObject } from '../../utils/utilities';
import EditValuation from './Models/EditValuation';
import { getMvUpdateTypeLabel, getPropertyTypeLabel } from '../../utils/ConstLabel';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import './valuation.css';

const Valuation = () => {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm();
  const [allValuations, setAllValuations] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Updated default filter to include status
  const defaultFilter = {
    from_date: null,
    to_date: null,
    searchSlug: '',
    status: null,
  };

  const defaultPagination = {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    defaultPageSize: 10,
  };

  // Lazy initialization of filter state from localStorage
  const [filter, setFilter] = useState(() => {
    const storedFilter = JSON.parse(localStorage.getItem('valuationFilter'));
    return storedFilter || defaultFilter;
  });

  // Lazy initialization of pagination state from localStorage
  const [pagination, setPagination] = useState(() => {
    const storedPagination = JSON.parse(localStorage.getItem('valuationPagination'));
    return storedPagination || defaultPagination;
  });

  // Separate total from pagination state to prevent useEffect loops
  const [total, setTotal] = useState(0);

  const { allValuationsData, loadingAllValuations } = useSelector((state) => state.getAllValuationReducer);

  // Set form fields based on initialized filter and pagination
  useEffect(() => {
    filterForm.setFieldsValue({
      Search: filter.searchSlug || '',
      startDate: filter.from_date ? dayjs(filter.from_date) : null,
      endDate: filter.to_date ? dayjs(filter.to_date) : null,
      status: filter.status,
    });
  }, [filter, filterForm]);

  // Fetch data whenever filter or relevant pagination properties change
  useEffect(() => {
    fetchValuations();
    // Only depend on filter and relevant pagination properties
  }, [filter, pagination]);

  // Update localStorage when filters change
  useEffect(() => {
    localStorage.setItem('valuationFilter', JSON.stringify(filter));
  }, [filter]);

  // Update localStorage when pagination changes
  useEffect(() => {
    localStorage.setItem('valuationPagination', JSON.stringify(pagination));
  }, [pagination]);

  const fetchValuations = async () => {
    setLoading(true);
    const params = {
      ...filter,
      skip: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      sortField: filter.sortField,
      sortOrder: filter.sortOrder,
    };
    await dispatch(GetAllValuations(params));
    setLoading(false);
  };

  // Update allValuations and total when data is fetched
  useEffect(() => {
    if (isValidObject(allValuationsData)) {
      setAllValuations(allValuationsData.result.properties);
      setTotal(allValuationsData.result.count);
    }
  }, [allValuationsData]);

  const statusOptions = [
    { value: 'mv_updated', label: 'MV Updated' },
    { value: 'mv_not_updated', label: 'MV Pending ' },
    { value: 'mv_domain_tried_and_failed', label: 'Domain Tried And Failed' },
    { value: 'mv_domain_remaining', label: 'Domain Remaining' },
    { value: 'updated_by_domain', label: 'Updated By Domain' },
    { value: 'updated_by_admin', label: 'Updated By Admin' },
    // { value: 'not_updated_by_domain', label: 'Not Updated By Domain' },
  ];

  const handleModal = (record, callback) => {
    setModalData([record]);
    callback();
  };

  const SearchSlug = debounce((e) => {
    const value = e.target.value;
    setFilter((prev) => ({
      ...prev,
      searchSlug: value,
    }));
    setPagination((prev) => ({
      ...prev,
      current: 1,
    }));
  }, 300);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid() && dayjs(filter.to_date).isBefore(dayjs(value))) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be less than End Date.',
      });
      filterForm.setFieldsValue({ startDate: null });
      return;
    }
    setFilter((prev) => ({
      ...prev,
      from_date: value ? dayjs(value).format('YYYY-MM-DD') : '',
    }));
    setPagination((prev) => ({
      ...prev,
      current: 1,
    }));
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid() && dayjs(filter.from_date).isAfter(dayjs(value))) {
      notification.error({
        message: 'Failure',
        description: 'End Date should be greater than Start Date.',
      });
      filterForm.setFieldsValue({ endDate: null });
      return;
    }
    setFilter((prev) => ({
      ...prev,
      to_date: value ? dayjs(value).format('YYYY-MM-DD') : '',
    }));
    setPagination((prev) => ({
      ...prev,
      current: 1,
    }));
  };

  const tableColumns = [
    {
      title: 'Property Address',
      dataIndex: 'address',
      key: 'address',
      width: '438px',
      render: (_, record) => {
        const { street_number, street_name, suburb, state, postcode } = record;
        return <>{`${street_number} - ${street_name} ${suburb} ${state} ${postcode}`}</>;
      },
    },
    {
      title: 'Property Type',
      dataIndex: 'property_type',
      key: 'property_type',
      sorter: true,
      render: (text) => getPropertyTypeLabel(text),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text) => (text ? dayjs(text).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'User Market Value',
      dataIndex: 'market_value',
      key: 'market_value',
      sorter: true,
      render: (_, record) => {
        const { market_value = '-' } = record;
        return <>{market_value ? formatNumberToCurrency(market_value) : '-'}</>;
      },
    },
    {
      title: 'Admin Market Value',
      dataIndex: 'admin_market_value',
      key: 'admin_market_value',
      sorter: true,
      render: (_, record) => {
        const { admin_market_value = '-' } = record;
        return <>{admin_market_value ? formatNumberToCurrency(admin_market_value) : '-'}</>;
      },
    },
    {
      title: 'Date Updated',
      dataIndex: 'mv_updated_at',
      key: 'mv_updated_at',
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format('DD-MM-YYYY') : '-';
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'mv_updated_by',
      key: 'mv_updated_by',
      render: (text) => {
        return getMvUpdateTypeLabel(text);
      },
    },
    {
      title: 'Admin Name',
      dataIndex: 'admin_name',
      key: 'admin_name',
      render: (text, record) => record?.updatedByUser?.name || '-',
    },
    {
      title: 'Domain Status',
      dataIndex: 'domain_update_price_date',
      key: 'domain_update_price_date',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return !text ? <Tag color="red">Pending</Tag> : <Tag color="green">Updated</Tag>;
      },
    },
    {
      title: 'Edit',
      dataIndex: 'property',
      key: 'property',
      render: (_, record) => {
        return (
          <button className="btn btn-primary mt-xxl-0 mt-2 property_edit" onClick={() => handleModal(record, () => setModalOpen(true))}>
            <PencilIcon />
          </button>
        );
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter(defaultFilter);
    setPagination(defaultPagination);
    setTotal(0);
  };

  const handleTableChange = (newPagination, tableFilters, sorter) => {
    setPagination((prev) => ({
      ...prev,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    }));

    setFilter((prev) => ({
      ...prev,
      sortField: sorter.field,
      sortOrder: sorter.order, // 'ascend' or 'descend'
    }));
  };

  const handleStatusChange = (value) => {
    setFilter((prev) => ({
      ...prev,
      status: value,
    }));
    setPagination((prev) => ({
      ...prev,
      current: 1,
    }));
  };

  return (
    <>
      <div className="d-flex gap-3 flex-sm-column flex-md-row flex-lg-row loans-header-responsive justify-content-between align-items-center">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Property Market Value</h5>
          </div>
        </div>

        <div className="date_picker reset-width-100 ">
          <Form form={filterForm}>
            <div className="d-flex justify-content-between loans-header-responsive gap-3 align-items-center">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item
                    name="Search"
                    className="p-0 m-0"
                    rules={[
                      {
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input onChange={SearchSlug} allowClear style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100 status-selector">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="status" className="p-0 m-0">
                    <Select
                      style={{
                        background: 'transparent',
                        width: '100%',
                      }}
                      size="large"
                      placeholder="Valuation Filters"
                      allowClear
                      options={statusOptions}
                      onChange={handleStatusChange}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 reset-width-100">
                  <Button type="primary" icon={<ReloadOutlined />} size="large" className="reset-width-100 d-flex align-items-center" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              columns={tableColumns}
              dataSource={allValuations}
              loading={loadingAllValuations || loading}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: total,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
              onChange={handleTableChange}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
      </div>

      {isModalOpen && (
        <EditValuation
          isModalOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          data={modalData}
          filter={{ ...filter, skip: (pagination.current - 1) * pagination.pageSize, limit: pagination.pageSize }}
        />
      )}
    </>
  );
};

export default Valuation;
