import { Badge, Button, Flex, Input, notification, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { GetCompleteDepreciation } from '../../container/actions/depreciation/getCompleteDepriciation.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import { CheckCircleOutlined, CloseCircleOutlined, RedoOutlined, SyncOutlined } from '@ant-design/icons';
import { OCRStatus } from '../../components/common/userTypes';
import { DepreciationRegenerateOCR } from '../../container/actions/depreciation/getDepreciation.action';

const DepreciationCompleted = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const { depreciationDatacomplete, isLoading } = useSelector((state) => state.depreciationCompleteDataReducer);

  const navigate = useNavigate();

  useEffect(() => {
    const savedSearch = JSON.parse(localStorage.getItem('depreciationCompleteFilter'));
    if (savedSearch) {
      setSearchText(savedSearch);
    }
  }, []);

  useEffect(() => {
    dispatch(GetCompleteDepreciation());
  }, [dispatch]);

  useEffect(() => {
    if (depreciationDatacomplete && depreciationDatacomplete.result && Array.isArray(depreciationDatacomplete.result)) {
      let data = depreciationDatacomplete.result;
      if (searchText !== '') {
        data = data.filter((item) => {
          const email = item?.email?.toLowerCase() || '';
          const vemail = item?.virtual_email?.toLowerCase() || '';
          return email.includes(searchText.toLowerCase()) || vemail.includes(searchText.toLowerCase());
        });
      }
      setFilteredData(data);
    } else {
      setFilteredData([]);
    }
  }, [depreciationDatacomplete, searchText]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value === '') {
      localStorage.removeItem('depreciationCompleteFilter');
    }
  };

  const storeData = (record) => {
    navigate(`/depreciation/complete/${record?.depreciation?.id}`);
    localStorage.setItem('depreciationCompleteFilter', JSON.stringify(searchText));
  };

  const tableColumnscompleted = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => record.email,
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      render: (_, record) => record.virtual_email,
    },
  ];

  const handleRegenerateOCR = (record) => async () => {
    setLoading(true);
    const id = record?.depreciation?.depreciation_main?.id;
    try {
      const res = await dispatch(DepreciationRegenerateOCR(id));
      if (res.success) {
        notification.success({
          message: 'Success',
          description: 'Request to regenerate OCR has been sent successfully',
        });
        await dispatch(GetCompleteDepreciation());
      } else {
        notification.error({
          message: 'Failure',
          description: 'Error in regenerating OCR',
        });
      }
    } catch (err) {
      notification.error({
        message: 'Failure',
        description: err.message || 'Error in regenerating OCR',
      });
    }

    setLoading(false);
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Street Number',
        dataIndex: 'street_number',
        key: 'street_number',
        width: '20%',
      },
      {
        title: 'Street Name',
        dataIndex: 'street_name',
        key: 'street_name',
        width: '20%',
      },
      {
        title: 'Suburb',
        dataIndex: 'suburb',
        key: 'suburb',
        width: '20%',
      },
      {
        title: 'Property Type',
        dataIndex: 'property_type',
        key: 'property_type',
        width: '15%',
        render: (text) => getPropertyTypeLabel(text),
      },
      {
        title: 'Status',
        key: 'status',
        width: '20%',
        render: (_, record) => (record.depreciation === null ? <Badge status="warning" text="Pending" /> : <Badge status="success" text="Completed" />),
      },
      {
        title: 'OCR Status',
        dataIndex: 'ocr_status',
        key: 'ocr_status',
        render: (text, record) => {
          let tag;
          if (record.depreciation !== null) {
            if (record?.depreciation?.depreciation_main?.ocr_status === OCRStatus.OCR_REVIEW) {
              tag = (
                <Tag color="gold" icon={<CheckCircleOutlined />}>
                  Review Pending
                </Tag>
              );
            } else if (record?.depreciation?.depreciation_main?.ocr_status === OCRStatus.OCR_PENDING) {
              tag = (
                <Tag color="processing" icon={<SyncOutlined spin />}>
                  In-progress
                </Tag>
              );
            } else if (record?.depreciation?.depreciation_main?.ocr_status === OCRStatus.OCR_FAILED) {
              tag = (
                <Tag color="error" icon={<CloseCircleOutlined />}>
                  Failed
                </Tag>
              );
            } else if (record?.depreciation?.depreciation_main?.ocr_status === OCRStatus.OCR_COMPLETE) {
              tag = (
                <Tag color="success" icon={<CheckCircleOutlined />}>
                  Success
                </Tag>
              );
            } else {
              tag = '-';
            }
            return tag;
          }
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '20%',
        render: (_, record) => {
          return record.depreciation === null ? (
            '-'
          ) : (
            <Flex gap={'middle'}>
              <Button
                type="primary"
                onClick={() => {
                  storeData(record);
                }}
                icon={<PencilIcon className="img-fluid" />}
              />
              {![OCRStatus.OCR_PENDING, OCRStatus.OCR_REVIEW].includes(record?.depreciation?.depreciation_main?.ocr_status) && (
                <Button
                  type="primary"
                  title="Regenerate OCR"
                  icon={
                    <RedoOutlined
                      style={{
                        paddingTop: 3,
                      }}
                    />
                  }
                  onClick={handleRegenerateOCR(record)}
                />
              )}
            </Flex>
          );
        },
      },
    ];

    return <Table columns={columns} dataSource={record.properties} pagination={false} className="nestaed_tabel" rowKey={'id'} />;
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-xl-6 col-md-6 col-sm-6 col-12">
          <h5 className="m-0">Depreciation Completed</h5>
        </div>
        <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent">
          <Input.Search placeholder="Search by Email, Unique ID" allowClear value={searchText} size="middle" onChange={handleSearchChange} className="custom-search" />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              columns={tableColumnscompleted}
              loading={isLoading || loading}
              expandable={{
                expandedRowRender,
              }}
              pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
              rowKey={'id'}
              dataSource={filteredData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepreciationCompleted;
