import { Button, Flex, Input, Popconfirm, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import './Notification.css';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTemplateAsync, getTemplateListAsync, selectSmsTemplates, selectTemplateLoading } from '../../container/reducers/slices/Notification/NotificationTemplateSlice';
import { NotificationCategory, NotificationType } from '../../components/common/userTypes';
import AddTemplate from './AddTemplate';

const SMS = () => {
  const [open, setOpen] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [type, setType] = useState('');
  const [record, setRecord] = useState(null);

  const smsTemplates = useSelector(selectSmsTemplates);
  const [filteredTemplates, setFilteredTemplates] = useState(smsTemplates);

  const loading = useSelector(selectTemplateLoading);

  const dispatch = useDispatch();

  const handlePreviewConfirm = async (id) => {
    try {
      const res = await dispatch(deleteTemplateAsync(id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(getTemplateListAsync(NotificationType.SMS));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message,
      });
    }
  };

  function handleTemplateEdit(record) {
    setType('sms');
    setIsEdited(true);
    setRecord(record);
    setOpen(true);
  }

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleModalOpen = () => {
    setOpen(true);
    setType('sms');
  };

  useEffect(() => {
    const filteredData = smsTemplates.filter((template) => template.name.toLowerCase().includes(searchValue.toLowerCase()) || template.category.toString().includes(searchValue));
    setFilteredTemplates(filteredData);
  }, [searchValue, smsTemplates]);

  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '20%',
      render: (_, record) => <span className="templateCategory"> {Object.keys(NotificationCategory).find((key) => NotificationCategory[key] === record.category)} </span>,
    },
    {
      title: 'Status',
      key: 'is_active',
      dataIndex: 'is_active',
      sorter: (a, b) => a.is_active - b.is_active,

      width: '20%',
      render: (text) => <Tag color={text === 0 ? 'red' : 'green'}>{text === 0 ? 'Inactive' : 'Active'}</Tag>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <Flex gap={'middle'}>
          <Button icon={<EditOutlined />} onClick={() => handleTemplateEdit(record)} type="primary" size="large" />
          <Popconfirm title="Delete the template" onConfirm={() => handlePreviewConfirm(record.id)} okText="Yes" cancelText="No">
            <Button icon={<DeleteOutlined />} type="primary" size="large" />
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex flex-column gap-5 align-items-center bg-white p-4 email-template-parent">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex gap-3 justify-content-between align-items-center">
            <div>
              <h5 className="m-0 p-0">SMS Templates</h5>
            </div>
            <div>
              <Input.Search onChange={(e) => handleSearch(e.target.value)} allowClear placeholder="Search SMS template" className="email-input" />
            </div>
          </div>
          <div>
            <Button type="primary" size="large" onClick={() => handleModalOpen()}>
              New Template
            </Button>
          </div>
        </div>

        <div className="row w-100">
          <div className="col-12">
            <div className="dashboard_list">
              <Spin spinning={loading} size="large">
                <Table
                  columns={columns}
                  dataSource={filteredTemplates}
                  rowKey={'id'}
                  pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />
              </Spin>
            </div>
          </div>
        </div>
      </div>

      {/* Add or edit sms template */}

      <AddTemplate open={open} setOpen={setOpen} isEdited={isEdited} setIsEdited={setIsEdited} record={record} type={type} setType={setType} setRecord={setRecord} />
    </>
  );
};

export default SMS;
