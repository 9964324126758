import { postRequest, putRequest } from '../../../utils/apiUtils';
import { PendingRentSummaryFailure, PendingRentSummarySuccess } from '../../reducers/slices/RentSummary/getsummarySlice';

export const GetPendingSummaryList = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/fetch-rent/pending`, data);
    if (!error) {
      return dispatch(PendingRentSummarySuccess(result));
    }
    return dispatch(PendingRentSummaryFailure(result));
  };
};

export const RentRegenerateOCR = (id) => {
  return async () => {
    const { result, error } = await putRequest(`admin/rent-ocr-regenerate/${id}`);
    if (!error) {
      return result;
    }
    return error;
  };
};
