import { Form, notification, Select, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesAsync, selectCategories, selectCategoriesLoading, updateCategoriesAsync } from '../../container/reducers/slices/Categories/CategoriesSlice';
import './Categories.css';

const Categories = () => {
  const dispatch = useDispatch();

  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  const loading = useSelector(selectCategoriesLoading);
  const data = useSelector(selectCategories);

  // Update local state when Redux data changes
  useEffect(() => {
    if (data) {
      setCategoriesData(
        data.map((category) => ({
          ...category,
          keywords: category.category_keyword ? category.category_keyword.split(',') : [],
        })),
      );
    }
  }, [data]);

  const handleKeywordsChange = async (value, record) => {
    const { id } = record;

    const updatedCategories = categoriesData.map((category) => (category.id === record.id ? { ...category, keywords: value, category_keyword: value.join(',') } : category));

    setCategoriesData(updatedCategories);

    const res = await dispatch(
      updateCategoriesAsync({
        id,
        keywords: { keyword: value.join(',') },
      }),
    );

    if (res?.payload?.data?.success) {
      notification.success({
        message: 'Success',
        description: 'Keywords updated successfully',
      });

      await dispatch(getCategoriesAsync());
    }
  };

  const columns = [
    {
      title: 'Category Name',
      dataIndex: 'category_name',
      key: 'category_name',
      width: '25%',
    },
    {
      title: 'Expense Type',
      dataIndex: 'expense_type',
      key: 'expense_type',
      width: '15%',
      render: (text, record) => `${text} (${record.transaction_type})`,
    },
    {
      title: 'Category Keywords',
      dataIndex: 'keywords',
      key: 'keywords',
      width: '60%',
      render: (text, record) => (
        <Form.Item className="categorySelect">
          <Select
            className="categorySelect"
            mode="tags"
            tokenSeparators={[',']}
            value={record.keywords || []}
            onChange={(value) => handleKeywordsChange(value, record)}
            placeholder="Enter new keywords"
          />
        </Form.Item>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex gap-3 flex-sm-column loans-header-responsive flex-md-row flex-lg-row justify-content-between align-items-center">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Rental Categories</h5>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="mt-3">
          <div className="row mt-4">
            <div className="col-12">
              <div className="dashboard_list">
                <Table columns={columns} dataSource={categoriesData} pagination={{ showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }} />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Categories;
