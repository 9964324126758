import { Form, Input, Select, Spin, Table, Tag, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { GetLoansList } from '../../container/actions/Loans/getLoans.action';
import { GetBankList } from '../../container/actions/Loans/getBankList.action';
import { ReloadOutlined } from '@ant-design/icons';

const Loan = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    banks: [],
    connection_status: '',
    searchSlug: '',
  });
  const [loading, setLoading] = useState(false);
  const [loansData, setLoansData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const bankList = useSelector((state) => state.getBankListReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(GetLoansList(filter)).then((res) => {
      if (res?.payload?.success === true) {
        setLoansData(res.payload.result);
        setLoading(false);
      }
    });
  }, [filter, dispatch]);

  useEffect(() => {
    if (!bankList?.data?.result) {
      dispatch(GetBankList());
    }
  }, [bankList?.data?.result, dispatch]);

  useEffect(() => {
    if (bankList?.data?.result && bankList.error === false) {
      const drpdown = bankList?.data.result.map((e, i) => {
        return { value: e.id, label: e.shortName, key: crypto.randomUUID() };
      });
      setBankData(drpdown);
    }
  }, [bankList]);

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const loansColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      fixed: 'left',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
      key: 'bank_name',
      render: (text, record) => {
        if (record.divider) {
          return '';
        }
        return record.extra ? '' : text;
      },
    },
    {
      title: 'BSB',
      dataIndex: 'bank_bsb_number',
      key: 'bank_bsb_number',
      fixed: true,
      render: (text, record) => {
        if (record.divider) {
          return '';
        }
        return record.extra ? '' : text;
      },
    },
    {
      title: 'Account No',
      dataIndex: 'bank_account_number',
      key: 'bank_account_number',
      fixed: true,
      render: (text, record) => {
        if (record.divider) {
          return '';
        }
        return record.extra ? '' : text;
      },
    },
    {
      title: 'Connect / Disconnect',
      dataIndex: 'connected',
      key: 'connected',
      render: (text) => {
        return text ? <Tag color="green">Connected</Tag> : <Tag color="red">Disconnected</Tag>;
      },
    },
    {
      title: 'Last Transaction Date',
      dataIndex: 'last_transaction_date',
      key: 'last_transaction_date',
      sorter: (a, b) => {
        // Check if either value is null and handle accordingly
        if (a.last_transaction_date === null && b.last_transaction_date === null) {
          return 0; // Both are null, considered equal
        } else if (a.last_transaction_date === null) {
          return -1; // Consider nulls as less than any date
        } else if (b.last_transaction_date === null) {
          return 1; // Consider nulls as less than any date
        }

        // If neither value is null, proceed with localeCompare
        return a.last_transaction_date.localeCompare(b.last_transaction_date);
      },
      // sorter: (a, b) => a.account_refresh_date.localeCompare(b.account_refresh_date),
      render: (text) => {
        return text ? dayjs(text).format('DD-MM-YYYY') : '-';
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      banks: [],
      connection_status: '',
      searchSlug: '',
    });
  };

  return (
    <>
      <div className="d-flex gap-3 flex-sm-column flex-md-row flex-lg-row loans-header-responsive justify-content-between align-items-center">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">User Loans</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex gap-3 loans-header-responsive align-items-center justify-content-around">
              <div className="w-50 reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-0 m-0">
                    <Input onChange={(e) => SearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="connection_status" className="m-0">
                    <Select
                      placeholder="Connection Status"
                      optionFilterProp="children"
                      options={[
                        { label: 'All', value: null },
                        { label: 'Connected', value: 'connected' },
                        { label: 'Disconnected', value: 'disconnected' },
                        { label: 'Update before 30 Days', value: 'before_30_day' },
                        { label: 'Update within 30 Days', value: 'within_30_day' },
                      ]}
                      style={{ width: '100%', background: 'transparent' }}
                      onChange={(e) => setFilter({ ...filter, connection_status: e })}
                      variant="borderless"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="w-50 reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="banks" className="m-0">
                    <Select
                      placeholder="Select Bank"
                      className="bank_name"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                      options={bankData}
                      mode="multiple"
                      maxTagCount="responsive"
                      style={{ width: '100%', background: 'transparent' }}
                      onChange={(e) => setFilter({ ...filter, banks: e })}
                      variant="borderless"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="w-25 reset-width-100 ">
                <div className="mb-3 mb-lg-0">
                  <Button icon={<ReloadOutlined />} type="primary" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table
                rowClassName={(record, index) => {
                  return record.transaction_before_30_day === '1' ? 'loan-highlight-row' : '';
                }}
                pagination={{
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                columns={loansColumn}
                className="user-loan-table"
                dataSource={loansData}
                rowKey={'id'}
                scroll={{ x: 'max-content' }}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Loan;
