import { Input, Table, Tag, Button, notification, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { CheckCircleOutlined, CloseCircleOutlined, RedoOutlined, SyncOutlined } from '@ant-design/icons';
import { GetAllDepreciation } from '../../container/actions/depreciation/getAllDepreciation.action';
import { OCRStatus } from '../../components/common/userTypes';
import { DepreciationRegenerateOCR } from '../../container/actions/depreciation/getDepreciation.action';

const DepreciationPending = () => {
  const dispatch = useDispatch();
  const [depreciationList, setDepreciationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  const { depreciationData, isLoading } = useSelector((state) => state.depreciationReducer);

  useEffect(() => {
    const savedSearch = JSON.parse(localStorage.getItem('depreciationFilter'));
    if (savedSearch) {
      setSearchText(savedSearch);
    }
  }, []);

  useEffect(() => {
    dispatch(GetAllDepreciation());
  }, [dispatch]);

  useEffect(() => {
    if (depreciationData && depreciationData.result && Object.keys(depreciationData.result).length > 0) {
      const allSet = depreciationData.result.map((res) => {
        return { ...res, key: crypto.randomUUID() };
      });
      setDepreciationList(allSet);
    } else {
      setDepreciationList([]);
    }
  }, [depreciationData]);

  // Filter logic based on searchText
  useEffect(() => {
    if (searchText !== '') {
      const filteredData = depreciationData?.result?.filter((item) => {
        const email = item?.user?.email?.toLowerCase() || '';
        const vemail = item?.user?.virtual_email?.toLowerCase() || '';
        return email.includes(searchText.toLowerCase()) || vemail.includes(searchText.toLowerCase());
      });
      setDepreciationList(filteredData);
    } else {
      // If no search text, show full list
      setDepreciationList(depreciationData?.result?.map((res) => ({ ...res, key: crypto.randomUUID() })) || []);
    }
  }, [searchText, depreciationData]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value === '') {
      localStorage.removeItem('depreciationFilter');
    }
  };

  function storeData() {
    localStorage.setItem('depreciationFilter', JSON.stringify(searchText));
  }

  const handleRegenerateOCR = (id) => async () => {
    setLoading(true);
    try {
      const res = await dispatch(DepreciationRegenerateOCR(id));
      if (res.success) {
        notification.success({
          message: 'Success',
          description: 'Request to regenerate OCR has been sent successfully',
        });
        await dispatch(GetAllDepreciation());
      } else {
        notification.error({
          message: 'Failure',
          description: 'Error in regenerating OCR',
        });
      }
    } catch (err) {
      notification.error({
        message: 'Failure',
        description: err.message || 'Error in regenerating OCR',
      });
    }

    setLoading(false);
  };

  const handleClickPending = (record) => {
    navigate(`/depreciation/pending/${record.id}`);
  };

  const tableColumns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => {
        return <>{record?.user?.email}</>;
      },
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      render: (_, record) => {
        return <>{record?.user?.virtual_email}</>;
      },
    },
    {
      title: 'OCR',
      dataIndex: 'ocr_status',
      key: 'ocr_status',
      render: (text) => {
        return text !== OCRStatus.OCR_REVIEW ? (
          text === OCRStatus.OCR_PENDING ? (
            <Tag color="processing" icon={<SyncOutlined spin />}>
              In-progress
            </Tag>
          ) : (
            <Tag color="error" icon={<CloseCircleOutlined />}>
              Failed
            </Tag>
          )
        ) : (
          <Tag color="success" icon={<CheckCircleOutlined />}>
            Success
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'user',
      key: 'action',
      render: (_, record) => {
        return (
          <Flex gap={'middle'}>
            <Button
              type="primary"
              onClick={() => {
                handleClickPending(record);
                storeData();
              }}
              icon={<PencilIcon className="img-fluid" />}
            />
            {record.ocr_status !== OCRStatus.OCR_REVIEW && record.ocr_status !== OCRStatus.OCR_PENDING && (
              <Button
                type="primary"
                title="Regenerate OCR"
                icon={
                  <RedoOutlined
                    style={{
                      paddingTop: 3,
                    }}
                  />
                }
                onClick={handleRegenerateOCR(record.id)}
              />
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <div className="row align-items-center">
        <div className="col-xl-6 col-md-6 col-sm-6 col-12">
          <h5 className="m-0">Depreciation Pending</h5>
        </div>
        <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent">
          <Input.Search placeholder="Search by Email, Unique ID" allowClear value={searchText} size="middle" onChange={handleSearchChange} className="custom-search" />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
              columns={tableColumns}
              dataSource={depreciationList}
              loading={isLoading || loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepreciationPending;
