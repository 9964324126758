import { getRequest, putRequest } from '../../../utils/apiUtils';
import { depreciationByIdFailure, depreciationByIdSuccess, resetDepreciationById } from '../../reducers/slices/Depreciation/depreciationByIdSlice';

export const GetDepreciationById = (id) => {
  return async (dispatch) => {
    dispatch(resetDepreciationById());
    const { result, error } = await getRequest(`admin/fetch-depreciation/pending/${id}`);

    if (!error) {
      return dispatch(depreciationByIdSuccess(result));
    }
    return dispatch(depreciationByIdFailure(result));
  };
};

export const DepreciationRegenerateOCR = (id) => {
  return async () => {
    const { result, error } = await putRequest(`admin/depreciation-ocr-regenerate/${id}`);
    if (!error) {
      return result;
    }
    return error;
  };
};
