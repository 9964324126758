import { Button, Flex, Input, Popconfirm, Table, Tabs } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteFinancialYearRentSummaryAsync,
  getFinancialYearRentSummaryCompletedListAsync,
  getFinancialYearRentSummaryPendingListAsync,
  selectFYCompletedList,
  selectFYLoading,
  selectFYPendingList,
} from '../../container/reducers/slices/FYRentSummary/FYRentSummarySlice';
import { tablePropertyType } from '../../components/common/userTypes';
import { getYearList } from '../../utils';

const EndOfYear = () => {
  const navigate = useNavigate();
  const { status } = useParams();
  const [activeIndex, setActiveIndex] = useState('1');
  const [searchText, setSearchText] = useState('');
  const pending = useSelector(selectFYPendingList);
  const pendingData = pending?.data;
  const completed = useSelector(selectFYCompletedList);
  const completedData = completed?.data;
  const [total, setTotal] = useState(0);
  const loading = useSelector(selectFYLoading);
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });

  const [filters, setFilters] = useState({
    searchSlug: '',
  });

  const resetFilters = () => {
    setFilters({
      searchSlug: '',
    });
    setPagination({ current: 1, pageSize: 10, defaultPageSize: 10 });
  };

  useEffect(() => {
    if (status === 'pending') {
      setTotal(pending?.total);
      setActiveIndex('1');
    } else if (status === 'completed') {
      setTotal(completed?.total);
      setActiveIndex('2');
    }
  }, [status, navigate, pending, completed]);

  useEffect(() => {
    if (activeIndex === '1') {
      dispatch(getFinancialYearRentSummaryPendingListAsync({ ...filters, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize }));
    }
    if (activeIndex === '2') {
      dispatch(getFinancialYearRentSummaryCompletedListAsync({ ...filters, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize }));
    }
  }, [dispatch, activeIndex, filters, pagination]);

  const setFiltersDebounced = useCallback(
    debounce((values) => {
      setFilters((prevFilters) => ({ ...prevFilters, searchSlug: values }));
    }, 300),
    [],
  );

  const handleDeleteEntry = async (id) => {
    await dispatch(deleteFinancialYearRentSummaryAsync(id));
    if (activeIndex === '1') {
      await dispatch(getFinancialYearRentSummaryPendingListAsync({ ...filters, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize }));
    }
    if (activeIndex === '2') {
      await dispatch(getFinancialYearRentSummaryCompletedListAsync({ ...filters, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize }));
    }
  };

  const handleTableChange = (tablePagination) => {
    setPagination(tablePagination);
  };

  const handleChange = (e) => {
    const values = e.target.value.trim();
    setSearchText(values);
    setFiltersDebounced(values);
  };
  const handleClick = (index, route) => {
    if (index !== activeIndex) {
      setSearchText('');
    }
    if (index === '1') {
      navigate(route);
      setActiveIndex(index);
      resetFilters();
    }
    if (index === '2') {
      navigate(route);
      setActiveIndex(index);
      resetFilters();
    }
  };

  const pendingColumns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <span>{text}</span>,
    },

    {
      title: 'Date',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (text) => <span>{dayjs(text).format('DD-MM-YYYY')}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Flex gap={'middle'}>
          <Button
            icon={<EditOutlined />}
            type="primary"
            size="large"
            onClick={() => {
              navigate(`/end-of-year/pending/${record.id}`);
            }}
          />
          <Popconfirm title="Delete this entry?" onConfirm={() => handleDeleteEntry(record.id)} okText="Yes" cancelText="No">
            <Button
              className="Delete_btn"
              size="large"
              icon={
                <DeleteOutlined
                  style={{
                    fontSize: 18,
                    paddingTop: 3,
                  }}
                />
              }
              type="primary"
            />
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  const completedColumns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Property Name',
      key: 'property',
      dataIndex: 'created_at',
      render: (_, record) => (
        <span>
          {record.street_number} {record.street_name}
        </span>
      ),
    },
    {
      title: 'Financial Year',
      key: 'financial_year',
      dataIndex: 'financial_year',
      render: (text) => <span>{getYearList()?.find((item) => item?.value === text)?.label}</span>,
    },
    {
      title: 'Property Type',
      key: 'property_type',
      dataIndex: 'property_type',
      render: (text) => <span>{Object.keys(tablePropertyType).find((key) => tablePropertyType[key] === text)}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Flex gap={'middle'}>
          <Button
            icon={<EditOutlined />}
            type="primary"
            size="large"
            onClick={() => {
              navigate(`/end-of-year/completed/${record.id}`);
            }}
          />
          <Popconfirm title="Delete this entry?" onConfirm={() => handleDeleteEntry(record.id)} okText="Yes" cancelText="No">
            <Button
              className="Delete_btn"
              size="large"
              icon={
                <DeleteOutlined
                  style={{
                    fontSize: 18,
                    paddingTop: 3,
                  }}
                />
              }
              type="primary"
            />
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <div className="row align-items-center">
        <div className="row align-items-center mt-2 custom-documentation">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
              <h5 className="m-0">End Of Financial Year</h5>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent ">
            <Input.Search placeholder="Search by Email" allowClear value={searchText} size="middle" onChange={(e) => handleChange(e)} className="custom-search" />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <Tabs
          activeKey={activeIndex}
          onTabClick={(value) => {
            if (value === '1') {
              handleClick('1', '/end-of-year/pending');
            }
            if (value === '2') {
              handleClick('2', '/end-of-year/completed');
            }
          }}
        >
          <Tabs.TabPane tab="Pending" key="1">
            <div className="row mt-4">
              <div className="col-12">
                <div className="dashboard_list">
                  <Table
                    columns={pendingColumns}
                    pagination={{ ...pagination, total: total, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                    dataSource={pendingData}
                    loading={loading}
                    onChange={handleTableChange}
                  />
                </div>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Completed" key="2">
            <div className="row mt-4">
              <div className="col-12">
                <div className="dashboard_list">
                  <Table
                    columns={completedColumns}
                    dataSource={completedData}
                    pagination={{ ...pagination, total: total, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                    loading={loading}
                    onChange={handleTableChange}
                  />
                </div>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default EndOfYear;
