import { Modal } from 'antd';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import AntdLoader from '../../../components/common/antdLoader';
import { GetAllValuations } from '../../../container/actions/valuations/getAllValuations.action';
import { UpdatePropertyValuation } from '../../../container/actions/valuations/updatePropertyValuation.action';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { onlyNumber, SendNotification } from '../../../utils/utilities';
import { DollarIcon } from '../../../assets/icons';
import { PropertyType } from '../../../components/common/userTypes';

const EditValuation = ({ isModalOpen, closeModal, data, filter }) => {
  const dispatch = useDispatch();

  const updateFormSchema = yup.object({
    // title_owner: yup.string().required('Property name is required').nullable(),
    street_name: yup.string().required('Street name is required').nullable(),
    street_number: yup.string().required('Street is required'),
    suburb: yup.string().required('Suburn is required').nullable(),
    state: yup.string().required('State name is required').nullable(),
    postcode: yup.string().required('Postcode is required').nullable(),
    min_value: yup
      .string()
      .required('Minimum value is required')
      .test('min_value', `Value can't be zero`, function () {
        const { min_value } = this.parent;

        if (min_value === null || !Number.isFinite(1 / min_value)) {
          return false;
        }

        return true;
      })
      .nullable(),
    max_value: yup
      .string()
      .required('Maximum value is required')
      .test('max_value', `Value can't be zero`, function () {
        const { max_value } = this.parent;

        if (max_value === null || !Number.isFinite(1 / max_value)) {
          return false;
        }

        return true;
      })
      .nullable(),
    market_value: yup.string().required('Market value is required'),
  });

  const [formSubmitting, setFormSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateFormSchema),
    mode: 'onSubmit',
  });

  const handleUpdateProperty = (formData) => {
    setFormSubmitting(true);
    const [{ id }] = data;
    dispatch(UpdatePropertyValuation({ id, data: formData })).then(({ payload: { success, message } }) => {
      if (success) {
        dispatch(GetAllValuations(filter));
        closeModal();
        SendNotification({ type: 'success', message });
        setFormSubmitting(false);
        return;
      }

      SendNotification({ type: 'error', message: 'something went wrong' });
      setFormSubmitting(false);
    });
  };

  return (
    <Fragment>
      <Modal
        title="Update property valuation"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={closeModal}
        footer={null}
      >
        <form onSubmit={handleSubmit(handleUpdateProperty)}>
          {data.map(({ street_number, street_name, suburb, state, postcode, title_owner, min_value, name, max_value, market_value, admin_market_value }, i) => {
            return (
              <Fragment key={i}>
                <div className="row">
                  {/* <div className="col-12">
                                            <div>
                                                <label
                                                    className="form-label edit_valuation"
                                                >
                                                    Property Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title_owner"
                                                    {...register("title_owner", {
                                                        value: title_owner,
                                                    })}
                                                />
                                            </div>
                                            <p className="text-danger mb-4">{errors.title_owner?.message}</p>
                                        </div> */}
                  <div className="col-6">
                    <div>
                      <label className="form-label edit_valuation">Street Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="street_name"
                        {...register('street_name', {
                          value: street_name,
                        })}
                      />
                    </div>
                    <p className="text-danger mb-4">{errors.street_name?.message}</p>
                  </div>
                  <div className="col-6">
                    <div>
                      <label className="form-label edit_valuation">Street</label>
                      <input
                        type="text"
                        className="form-control"
                        name="street_number"
                        {...register('street_number', {
                          value: street_number,
                        })}
                      />
                    </div>
                    <p className="text-danger mb-4">{errors.street_number?.message}</p>
                  </div>
                  <div className="col-6">
                    <div>
                      <label className="form-label edit_valuation">Suburb</label>
                      <input
                        type="text"
                        className="form-control"
                        name="suburb"
                        {...register('suburb', {
                          value: suburb,
                        })}
                      />
                    </div>
                    <p className="text-danger mb-4">{errors.suburb?.message}</p>
                  </div>
                  <div className="col-6">
                    <div>
                      <label htmlFor="disableduseremail" className="form-label edit_valuation">
                        State
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        {...register('state', {
                          value: state,
                        })}
                      />
                    </div>
                    <p className="text-danger mb-4">{errors.state?.message}</p>
                  </div>
                  <div className="col-12">
                    <div>
                      <label className="form-label edit_valuation">Postcode</label>
                      <input
                        type="text"
                        className="form-control"
                        name="postcode"
                        {...register('postcode', {
                          value: postcode,
                        })}
                      />
                    </div>
                    <p className="text-danger mb-4">{errors.postcode?.message}</p>
                  </div>

                  <div className="col-xl-6 col-md-6 col-12">
                    <div>
                      <label className="form-label edit_valuation">Value (Min)</label>
                      <div className="position-relative">
                        <DollarIcon className="dollar_sign" />

                        <input
                          type="text"
                          className="form-control ps-4"
                          name="min_value"
                          onKeyPress={(e) => onlyNumber({ e })}
                          {...register('min_value', {
                            value: min_value,
                          })}
                        />
                      </div>
                    </div>
                    <p className="text-danger mb-4">{errors.min_value?.message}</p>
                  </div>
                  <div className="col-xl-6 col-md-6 col-12">
                    <div>
                      <label className="form-label edit_valuation">Value (Max)</label>
                      <div className="position-relative">
                        <DollarIcon className="dollar_sign" />
                        <input
                          type="text"
                          className="form-control ps-4"
                          name="max_value"
                          onKeyPress={(e) => onlyNumber({ e })}
                          {...register('max_value', {
                            value: max_value,
                          })}
                        />
                      </div>
                    </div>
                    <p className="text-danger mb-4">{errors.max_value?.message}</p>
                  </div>
                  <div className="col-xl-6 col-md-6 col-12">
                    <div>
                      <label className="form-label edit_valuation">User Market Value</label>
                      <div className="position-relative">
                        <DollarIcon className="dollar_sign" />
                        <input
                          type="text"
                          disabled
                          className="form-control ps-4"
                          name="market_value"
                          onKeyPress={(e) => onlyNumber({ e })}
                          {...register('market_value', {
                            value: market_value === null ? 0 : market_value,
                          })}
                        />
                      </div>
                    </div>
                    <p className="text-danger mb-4">{errors.market_value?.message}</p>
                  </div>
                  {data[0].property_type === PropertyType.COMMERCIAL_PROPERTY ? (
                    ''
                  ) : (
                    <div className="col-xl-6 col-md-6 col-12">
                      <div>
                        <label className="form-label edit_valuation">Admin Market Value</label>
                        <div className="position-relative">
                          <DollarIcon className="dollar_sign" />
                          <input
                            type="text"
                            className="form-control ps-4"
                            name="market_value"
                            onKeyPress={(e) => onlyNumber({ e })}
                            {...register('admin_market_value', {
                              value: admin_market_value,
                            })}
                          />
                        </div>
                      </div>
                      <p className="text-danger mb-4">{errors.admin_market_value?.message}</p>
                    </div>
                  )}
                </div>
                <div className="row justify-content-sm-end justify-content-center text-sm-end text-center g-0">
                  <div className="col-xl-2 col-md-3 col-sm-3 col-5">
                    <button type="button" className="btn btn-outline-primary px-3 cancel_button" onClick={() => closeModal()}>
                      Cancel
                    </button>
                  </div>
                  <div className="col-xl-3 col-md-3 col-sm-3 col-5">
                    <button type="submit" className="btn btn-primary px-3 button_primary">
                      {/* Update */}
                      {formSubmitting ? <AntdLoader /> : 'Update'}
                    </button>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </form>
      </Modal>
    </Fragment>
  );
};

export default EditValuation;
